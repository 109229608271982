<template>
	<v-layout column fill-height>
		<iframe v-if="iFrameURI" id="rocket-chat" class="grow" title="chat" :src="iFrameURI" @load="logUser" />
	</v-layout>
</template>

<script>
import ChatModuleGuard from '@/mixins/ModulesGuards/Chat/ChatModuleGuard'

export default {
	name: 'ChatContent',
	mixins: [ChatModuleGuard],
	data: function () {
		return {
			currentChannel: null,
			isLogged: false,
			token: null
		}
	},
	computed: {
		iFrameURI: function () {
			return this.currentChannel ? this.$envVar.VUE_APP_CHAT_URL + '/channel/' + this.currentChannel + '?layout=embedded' : null
		}
	},
	watch: {
		vendorId: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.getChannel(newVal)
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.$emit('created')
	},
	destroyed: function () {
		this.service.logoutUser(this.accountingFirmId, this.token)
	},
	methods: {
		getChannel: function (vendorId = null) {
			this.service.getChannel(this.accountingFirmId, vendorId).then(res => {
				this.currentChannel = res?.name
			})
		},
		logUser: function () {
			this.service.getToken().then(accessToken => {
				this.token = accessToken
				window.document.getElementById('rocket-chat').contentWindow.postMessage(
					{
						externalCommand: 'login-with-token',
						token: accessToken
					},
					this.$envVar.VUE_APP_CHAT_URL
				)
			})
		}
	}
}
</script>
